/* ==========================================================================
   Accueil
   ========================================================================== */

   .main-accueil {
    @extend .text-center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    @media(min-width: $screen-sm-min) {
      background-color: rgba(black, .5);
    }
  }

  .content-main-accueil {
    // max-width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: height ease-in-out .5s;
    // gap:10px;

    @media(min-width: $grid-float-breakpoint) {
      flex-direction: row;
    }

    .col-logo {
      @extend .animated;
      @extend .flipInY;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      // background-color: white;
      color: black;
      font-size: $font-size-base;
      margin-top: 0;
      transition: all ease-in-out .5s;
         background: $brand-primary;
      @media(min-width: $grid-float-breakpoint) {
        width: 35%;
      }

      >span {
        text-transform: uppercase;
      }

      .line-1 {
        font-size: 1.7em;
        padding-bottom: .1em;
        margin-bottom: .3em;
        // border-bottom: solid .1rem black;

        img{
          max-width: 70%;
        }
      }

      .line-2 {
        border-top: solid 1px $brand-primary;
        padding-top: 25px;
        font-size: .80em;
        padding-bottom: .3em;
        font-weight: bolder;
      }

      .line-3,
      .line-4 {
        line-height: 1.4;
        font-size: .9em;
      }
    }

    .col-slider {
      display: flex;
      align-items: center;
      width: 100%;

      >.carousel {
        width: 100%;
      }

      @media(min-width: $grid-float-breakpoint) {
        width: 70%;
      }
    }
  }

  .slider-first {
    @extend .carousel-fade;

    figure {
      height: 40rem;
      width: 90rem;
      max-width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  .btn-accueil {
    @extend .btn;
    @extend .btn-lg;
    @extend .btn-primary;
    font-weight: 300;
    margin: 5rem auto;
  }

  .footer-accueil {
    @extend .text-center;
    padding: 6rem 0;
    background-color: white;

    @media(min-width: $screen-sm-min) {
      background-color: rgba(white, .8);
    }

    h1 {
      font-size: 2.5rem;
    }
  }

  .col-footer-accueil-left {
    @extend .col-sm-6;
    @extend .text-left;

    @media (max-width: $screen-xs-max) {
      text-align: center;
    }
  }

  .col-footer-accueil-right {
    @extend .col-sm-6;
    @extend .text-right;

    @media (max-width: $screen-xs-max) {
      text-align: center;
      padding-top: 20px;
    }
  }

  .text-b{
    color:black !important;
  }
